import styled from 'styled-components';
// import { Link } from 'react-router-dom';

export const FooterContainer = styled.div`
  .first-row {
    display: grid;
    grid-gap: 1rem;

    .column {
      display: flex;
      flex-direction: column;
      margin-top: 4rem;
      align-items: start;

      .parent-address {
        margin-top: 100px;
      }

      .heading {
        font-weight: 600;
        font-size: 1rem;
        color: ${({ theme: { colors } }) => colors.headerColor};
        margin-left: 0rem;
      }
      &-item {
        color: #575757;
        margin-top: 1rem;
        text-align: start;
        font-size: 0.8rem;
      }
      &-item.bordered {
        border: 1px solid #575757;
        border-radius: 0.2rem;
        text-align: center;
        width: max-content;
        padding: 0.6rem 0.8rem;
      }

      .social-icon-container {
        display: flex;
        height: 30px;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 0px;
        margin-bottom: 0.4rem;

        .social-icon {
          width: 22px;
          height: 22px;
          padding: 4px;
          margin-right: 20px;
          margin-top: 1rem;

          img {
            width: 100%;
          }

          @media (max-width: 768px) {
            margin-right: 10px;
          }
        }
        .facebook {
          height: 15px;
          width: 15px;
        }
      }
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);

      .column {
        margin-top: 0.5rem;
      }
      .column.reduce {
        margin-top: -2.2rem;
      }
    }

    @media (max-width: 360px) {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }

    @media (min-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 1rem;
    }

    @media (min-width: 1024px) {
      grid-gap: 2rem;

      .column {
        margin-bottom: 4rem;
      }
    }

    @media (min-width: 1600px) {
      .column {
        margin-bottom: 4rem;
      }
    }
  }
  .second-row {
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 3rem;
    font-size: 0.8rem;
    color: #575757;
  }

  .parent-address {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
  .address {
    display: flex;
    background-color: white;
    box-shadow: 0 15px 10px #777;
    height: 200px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0 20px;
    border-radius: 8px;
  }
  .address h1 {
    color: #006399;
    font-weight: bold;
  }
  .p-one {
    color: #f56300;
    font-weight: bold;
  }
  .p-two {
    color: #006399;
    font-weight: bold;
  }

  .head-q {
    font-size: 40px;
    color: #f56300;
  }
`;
