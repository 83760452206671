import styled from 'styled-components';

export const PageWrapper = styled.div`
  min-height: 80vh;
  padding-top: 4rem;

  .theme-toggler {
  }

  @media (min-width: 768px) {
    padding-top: 5.3rem;

    .theme-toggler {
      top: 90vh;
      right: 2rem;
    }
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1600px) {
  }
`;

export const ThemeContainer = styled.div`
  position: fixed;
  top: 93vh;
  right: 0.7rem;

  input {
    &:checked + .slider {
      &:before {
        transform: translateX(25px);
      }

      &:after {
        content: '🌜';
        left: 0px;
        top: 3px;

        @media (max-width: 766px) {
          left: 2px;
        }
      }
    }
  }

  .slider {
    position: absolute;

    &:after {
      left: 28px;

      @media (max-width: 766px) {
        left: 28px;
      }
    }
  }
`;
