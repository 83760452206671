import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { StyledToggle } from './toggle-theme.styled';
import { selectDarkTheme } from '../../redux/ui/ui.selectors';
import { toggleTheme } from '../../redux/ui/ui.actions';

function ToggleTheme({ dark, dispatch }) {
  const toggle = () => {
    dispatch(toggleTheme());
  };

  return (
    <StyledToggle>
      <span className={`sun sun-logo ${dark ? '' : 'animate-sun'}`} onClick={toggle}>
        🌞
      </span>
      <span className={`moon moon-logo ${dark ? '' : 'animate-moon'}`} onClick={toggle}>
        🌜
      </span>
    </StyledToggle>
  );
}

const mapStateToProps = createStructuredSelector({
  dark: selectDarkTheme,
});

export default connect(mapStateToProps)(ToggleTheme);
