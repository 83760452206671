import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    * {
        box-sizing: border-box;
    }

    html {
        scroll-behavior: smooth;
    }

    body {
      margin: 0;
      padding: 0;
      min-height: 90vh;
      background-color: ${({ theme: { colors } }) => colors.bg};
      color: ${({ theme: { colors } }) => colors.color};
      overflow-x: hidden;
      font-family: 'Cabin', sans-serif;
      cursor: default;


      &.open-modal {
          // overflow: hidden !important;
      }
    }

    button {
      font-family: 'Cabin', sans-serif;
    }

    .cursor {
        width: 20px;
        height: 20px;
        border: 1px solid #fff;
        border-radius: 50%;
        position: absolute;
        transition-duration: 150ms;
        transition-timing-function: ease-out;
        animation: cursorAnim 0.5s infinite alternate;
        display: ${process.env.NODE_ENV === 'development' && 'none'}
    }

    .cursor::after {
        content: '';
        width: 20px;
        height: 20px;
        border: 8px solid #51B2F4;
        border-radius: 50%;
        position: absolute;
        opacity: 0.5;
        top: -8px;
        left: -8px;
        transition-duration: 150ms;
        transition-timing-function: ease-out;
        animation: cursorAnimAfter 0.5s infinite alternate;
    }

    .expand-cursor {
        animation: cursorExpand 0.4s forwards;
        border: 1px solid #ff822e;
    }

    @keyframes cursorAnim {
        from {
            transform: scale(1);
        }

        to {
            transform: scale(0.7);
        }
    }

    @keyframes cursorAnimAfter {
        from {
            transform: scale(1);
        }

        to {
            transform: scale(0.5);
        }
    }

    @keyframes cursorExpand {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(3);
        }

        100% {
            transform: scale(1);
            opacity: 0;
        }
    }


    a {
        text-decoration: none;
    }

    h1, h2, h3, h4, h5, h6 {
        margin-block-end: 0.2em;
        margin-block-start: 0.2em;
        margin-inline-start: 0em;
        margin-inline-end: 0.2em;
    }

    .container {
        padding: 0 1.5rem;
    }

    .h1 {
      font-size: ${({
        theme: {
          sizes: { h1 },
        },
      }) => h1.sm};
      font-weight: 500;
      color: ${({ theme: { colors } }) => colors.headerColor}
    }

    .h2 {
      font-size: ${({
        theme: {
          sizes: { h2 },
        },
      }) => h2.sm};
      font-weight: 500;
      color: ${({ theme: { colors } }) => colors.headerColor}
    }

    .h5 {
        color: #00cc88;
        text-transform: capitalize;
        font-weight: 500;
    }

    @media (min-width: 768px) {
        .container {
            padding: 0 3.5rem;
        }

        .h1 {
            font-size: ${({
              theme: {
                sizes: { h1 },
              },
            }) => h1.md};
        }

        .h2 {
            font-size: ${({
              theme: {
                sizes: { h2 },
              },
            }) => h2.md};
        }
    }

    @media (min-width: 1024px) {
        .container {
            padding: 0 4.5rem;
        }

        .h1 {
            font-size: ${({
              theme: {
                sizes: { h1 },
              },
            }) => h1.lg};
        }

        .h2 {
            font-size: ${({
              theme: {
                sizes: { h2 },
              },
            }) => h2.lg};
        }

    }

    @media (min-width: 1600px) {
        .container {
            padding: 0 8.5rem;
        }

        .h1 {
            font-size: ${({
              theme: {
                sizes: { h1 },
              },
            }) => h1.xl};
        }

        .h2 {
            font-size: ${({
              theme: {
                sizes: { h2 },
              },
            }) => h2.xl};
        }
    }

    .success-modal {
      width: 90%;
      height: 55%;
    }

    @media (min-width: 768px) {
      .success-modal {
        width: 40%;
        height: 50%;
      }
    }

    /* copy this media queries for ease of adding responsiveness 🤪 */
    /* Theme toggles 🌞 🌜 */
    @media (min-width: 768px) {
    }

    @media (min-width: 1024px) {
    }

    @media (min-width: 1600px) {
    }
`;

export default GlobalStyles;
