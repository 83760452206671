import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledHeader = styled.header`
  z-index: 100;
  background-color: ${({ theme: { theme } }) =>
    theme === 'dark' ? '#0A0A0A' : '#fcfcfc'};
  color: ${({ theme: { colors } }) => colors.color};
  box-shadow: 0px 4px 4px #d1cdcd29;
  width: 100vw;
  height: 4.5rem;
  position: fixed;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    height: 5rem;
    box-shadow: 0px 4px 4px #d1cdcd29;
  }

  @media (min-width: 1024px) {
    height: 6rem;
  }
`;

export const Nav = styled.nav`
  z-index: 100;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.98;
  width: 75%;
  background-color: ${({ theme: { theme } }) =>
    theme === 'dark' ? '#0A0A0A' : '#fcfcfc'};
  color: ${({ theme: { colors } }) => colors.color};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  transition: all 0.2s ease-in-out;
  /* box-shadow: -4px 1px 4px 0px #d1cdcd29; */

  .menu-nav {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-end;
    justify-content: center;
    height: 100vh;
    overflow: hidden;
    list-style-type: none;
    padding-right: 0.5rem;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
    transition: all 0.5s ease-in-out;

    &-item {
      margin-bottom: 1rem;
      transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100vw)')};
      transition: all 0.5s ease-in-out;

      &.active > a {
        color: ${({ theme }) => theme.colors.primary};

        &::after {
          content: '';
          position: absolute;
          top: 10%;
          left: 38%;
          width: 28px;
          height: 28px;
          background-color: ${({ theme }) => theme.colors.primary};
          opacity: 0.3;
          border-radius: 50%;
          z-index: 99;
        }
      }
    }

    &-link {
      display: inline-block;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      color: ${({ theme: { colors } }) => colors.color};
      text-decoration: none;
      padding: 0.3rem 1rem;
      transition: all 0.5s ease-in-out;
      position: relative;

      &.apply-btn {
        color: #fff;
        padding: 0.7rem 2rem;
        box-sizing: border-box;
        background-color: #ff822e;
        border-radius: 4px;

        &:hover {
          color: #fff;
        }

        &::after {
          // background-color: unset !important;
          opacity: 0.3;
        }
      }

      &:hover {
        color: ${({ theme }) => theme.colors.primary};
        transition: all 0.3s ease-in-out;

        &::after {
          content: '';
          position: absolute;
          top: 25%;
          left: 40%;
          width: 20px;
          height: 20px;
          background-color: ${({ theme }) => theme.colors.primary};
          opacity: 0.3;
          border-radius: 50%;
          z-index: 99;
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    visibility: visible;
    background-color: ${({ theme: { theme } }) =>
      theme === 'dark' ? '#0A0A0A' : '#fcfcfc'};
    width: 100%;
    padding-top: 1rem;

    .menu-nav {
      display: block;
      transform: translateY(0);
      height: 100%;
      text-align: right;
      margin-right: 3rem;

      &-item {
        display: inline;
        padding-right: 1.5rem;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    /* visibility: visible; */

    .menu-nav {
      margin-right: 5rem;
    }
  }
`;

export const LogoWrapper = styled(Link)`
  position: absolute;
  top: 0.9rem;
  left: 1rem;
  z-index: 101;

  img {
    width: 45px;
    height: 45px;
    object-fit: cover;
  }

  @media screen and (min-width: 768px) {
    top: 1.1rem;
    left: 3.5rem;

    img {
      width: 60px;
      height: 60px;
      object-fit: cover;
    }
  }

  @media screen and (min-width: 1024px) {
    left: 4.5rem;
  }

  @media screen and (min-width: 1600px) {
    left: 7.5rem;
  }
`;

const openMenuStyles = css`
  transform: rotate(450deg);
  background: transparent;

  &::before {
    transform: rotate(45deg) translate(5px, 8px);
  }

  &::after {
    width: 28px;
    transform: rotate(-45deg) translate(3px, -7px);
  }
`;

const toggleMenu = ({ open }) => open && openMenuStyles;

export const MenuButton = styled.div`
  position: absolute;
  z-index: 101;
  right: 1rem;
  top: 1rem;
  height: 20px;
  width: 20px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;

  .menu-btn-burger {
    position: absolute;
    right: 0;
    top: 1.3rem;
    width: 20px;
    height: 3px;
    background: ${({ theme }) => theme.colors.primary};
    transition: all 0.5s ease-in-out;
    border-radius: 2px;

    &::before {
      content: '';
      position: absolute;
      top: -8px;
      right: 0;
      width: 28px;
      height: 3px;
      background: ${({ theme }) => theme.colors.primary};
      transition: all 0.5s ease-in-out;
      border-radius: 2px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 8px;
      right: 0;
      width: 10px;
      height: 3px;
      background: ${({ theme }) => theme.colors.primary};
      transition: all 0.5s ease-in-out;
      border-radius: 2px;
    }

    ${toggleMenu}
  }

  @media screen and (min-width: 1024px) {
    visibility: hidden;
  }
`;

export const CustomApplyButton = styled(Link)`
  position: absolute;
  z-index: 101;
  right: 4rem;
  top: 1rem;
  cursor: pointer;
  color: #fff;
  padding: 0.6rem 0.9rem;
  box-sizing: border-box;
  background-color: #ff822e;
  border-radius: 4px;
  visibility: ${({ show }) => (show === 'true' ? 'visible' : 'hidden')};

  @media screen and (min-width: 768px) {
    visibility: hidden;
  }
`;
