import uiActionTypes from './ui.types';

let theme = false;

if (localStorage.theme) {
  theme = JSON.parse(localStorage.getItem('theme'));
}

const initialState = {
  open_nav: false,
  dark_theme: theme,
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case uiActionTypes.toggle_nav:
      return {
        ...state,
        open_nav: !state.open_nav,
      };

    case uiActionTypes.dark_theme:
      localStorage.setItem('theme', JSON.stringify(!state.dark_theme));

      return {
        ...state,
        dark_theme: !state.dark_theme,
      };

    case uiActionTypes.change_theme:
      return {
        ...state,
        dark_theme: action.payload,
      };

    default:
      return state;
  }
};

export default uiReducer;
