import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeTheme } from '../../redux/ui/ui.actions';

export const useThemeDetector = () => {
  const dispatch = useDispatch();

  const [isDarkTheme, setIsDarkTheme] = useState(
    () => window.matchMedia('(prefers-color-scheme: dark)').matches
  );
  const mqListener = (e) => {
    setIsDarkTheme(e.matches);
  };

  useEffect(() => {
    const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');

    darkThemeMq.addEventListener('change', mqListener);

    return () => darkThemeMq.removeEventListener('change', mqListener);
  }, []);

  useEffect(() => {
    if (localStorage.theme === undefined) {
      dispatch(changeTheme(isDarkTheme));
    }
  }, [isDarkTheme, dispatch]);

  return isDarkTheme;
};
