import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/index.css';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import { ToastProvider } from 'react-toast-notifications';

import store from './redux/store';
import config from './config';

if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = config.api_url;
} else {
  axios.defaults.baseURL = config.api_url_dev;
}

ReactDOM.render(
  <Provider store={store}>
    <ToastProvider>
      <App />
    </ToastProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();


/*
*  I just needed to test this out here, SORRY
*/