import React from 'react';
import { StyledFallback } from './suspense-fallback.styled';

export default function SuspeneFallback() {
  return (
    <StyledFallback>
      <div className="logo"></div>
    </StyledFallback>
  );
}
