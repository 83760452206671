import { combineReducers } from 'redux';

import uiReducer from './ui/ui.reducer';
import accountReducer from './account/account.reducer';

const rootReducer = combineReducers({
  ui: uiReducer,
  account: accountReducer,
});

export default rootReducer;
