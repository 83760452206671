import uiActionTypes from './ui.types';

export const toggleNav = () => ({
  type: uiActionTypes.toggle_nav,
});

export const toggleTheme = () => ({
  type: uiActionTypes.dark_theme,
});

export const changeTheme = (isDarkTheme) => ({
  type: uiActionTypes.change_theme,
  payload: isDarkTheme,
});
