import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ScrollToTop from './utils/ScrollToTop';
import Layout from './components/layout/layout.comp';
import SuspeneFallback from './components/suspense-fallback/suspense-fallback.comp';
import Error404 from './pages/404/404.page';
import ErrorBoundary from './components/error-boundary/error-boundary.comp';

const HomePage = lazy(() => import('./pages/home/home.page'));
const ContactPage = lazy(() => import('./pages/contact/contact.page'));
const Fellow = lazy(() => import('./pages/fellow/fellow.page'));
const Program = lazy(() => import('./pages/program/program.page'));
const ApplyPage = lazy(() => import('./pages/apply/apply.page'));
const AboutUsPage = lazy(() => import('./pages/about/about.page'));
const CommunityPage = lazy(() => import('./pages/community/community.page'));
const PublicationsPage = lazy(() => import('./pages/publications/publications.page'));
const PrivacyPage = lazy(() => import('./pages/privacy/privacy.page'));
const TermsPage = lazy(() => import('./pages/terms/terms.page'));
const BeginnersPage = lazy(() => import('./pages/beginners/beginners.page'));
const LogOrRegPage = lazy(() => import('./pages/logOrReg/logOrReg.page'));

const AppRoute = ({ component: Component, layout: CustomLayout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <CustomLayout>
        <Component {...props} />
      </CustomLayout>
    )}
  />
);

const Routes = () => {
  return (
    <Router>
      <ScrollToTop />
      <ErrorBoundary>
        <Suspense fallback={<SuspeneFallback />}>
          <Switch>
            <AppRoute exact path="/" layout={Layout} component={HomePage} />
            <AppRoute exact path="/about" layout={Layout} component={AboutUsPage} />
            <AppRoute exact path="/contact" layout={Layout} component={ContactPage} />
            <AppRoute exact path="/scholars" layout={Layout} component={Fellow} />
            <AppRoute exact path="/program" layout={Layout} component={Program} />
            <AppRoute exact path="/apply" layout={Layout} component={ApplyPage} />
            <AppRoute
              exact
              path="/foundation"
              layout={Layout}
              component={BeginnersPage}
            />
            <AppRoute exact path="/logOrReg" layout={Layout} component={LogOrRegPage} />
            <AppRoute exact path="/community" layout={Layout} component={CommunityPage} />
            <AppRoute
              exact
              path="/publications"
              layout={Layout}
              component={PublicationsPage}
            />
            <AppRoute exact path="/privacy" layout={Layout} component={PrivacyPage} />
            <AppRoute exact path="/terms" layout={Layout} component={TermsPage} />
            <Route component={Error404} />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </Router>
  );
};

export default Routes;
