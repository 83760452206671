import React from 'react';

import { PageWrapper } from './layout.styled';

import Hoc from '../../utils/Hoc';
import Navbar from '../navbar/navbar.comp';
import Footer from '../footer/footer.comp';
// import ToggleTheme from '../toggle-theme/toggle-theme.comp';

export default function Layout({ children }) {
  return (
    <Hoc>
      <Navbar />

      <PageWrapper>{children}</PageWrapper>

      {/* <ThemeContainer>
        <ToggleTheme />
      </ThemeContainer> */}
      <Footer />
    </Hoc>
  );
}
