import React from 'react';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { createStructuredSelector } from 'reselect';
import Routes from './routes';
import './index.css';
import { lightTheme, darkTheme } from './theme';
import GlobalStyles from './global.styled';
import { selectDarkTheme } from './redux/ui/ui.selectors';
import { useThemeDetector } from './utils/hooks/useThemeDetector';

function App({ dark }) {
  useThemeDetector();

  return (
    <ThemeProvider theme={dark ? darkTheme : lightTheme}>
      <GlobalStyles />
      <Routes />

      {/* <div className='cursor'></div> */}
    </ThemeProvider>
  );
}

const mapStateToProps = createStructuredSelector({
  dark: selectDarkTheme,
});

export default connect(mapStateToProps)(App);
