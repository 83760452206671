const accountTypes = {
  set_errors: 'set_errors',
  contact_loading: 'contact_loading',
  contact_success: 'contact_success',
  email_sub_loading: 'email_sub_loading',
  email_sub_success: 'email_sub_success',
  set_apply_loading: 'set_apply_loading',
  set_apply_success: 'set_apply_success',
  set_apply_errors: 'set_apply_errors',
};

export default accountTypes;
