import accountActionTypes from './account.types';

const initialState = {
  errors: {},
  contact_loading: false,
  contact_success: false,
  email_sub_loading: false,
  email_sub_success: false,
  apply_errors: {},
  apply_loading: false,
  apply_success: false,
  apply_available: true,
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case accountActionTypes.set_errors:
      return {
        ...state,
        errors: action.payload,
      };

    case accountActionTypes.contact_loading:
      return {
        ...state,
        contact_loading: action.payload,
      };

    case accountActionTypes.contact_success:
      return {
        ...state,
        contact_success: true,
      };

    case accountActionTypes.email_sub_loading:
      return {
        ...state,
        email_sub_loading: action.payload,
      };

    case accountActionTypes.email_sub_success:
      return {
        ...state,
        email_sub_success: true,
      };

    case accountActionTypes.set_apply_errors:
      return {
        ...state,
        apply_errors: action.payload,
      };

    case accountActionTypes.set_apply_loading:
      return {
        ...state,
        apply_loading: action.payload,
      };

    case accountActionTypes.set_apply_success:
      return {
        ...state,
        apply_success: true,
      };

    default:
      return state;
  }
};

export default accountReducer;
