import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { toggleNav } from '../../redux/ui/ui.actions';
import { selectOpenNav } from '../../redux/ui/ui.selectors';
import {
  StyledHeader,
  MenuButton,
  Nav,
  LogoWrapper,
  CustomApplyButton,
} from './navbar.styled';
import ToggleTheme from '../toggle-theme/toggle-theme.comp';
import { useOutsideNavClick } from '../../utils/hooks/useOutsideNavClick';

function Navbar({ dispatch, openNav }) {
  const navRef = useRef(null);
  const openMenu = () => dispatch(toggleNav());

  const closeMenu = () => openNav && dispatch(toggleNav());

  const path = useLocation().pathname;

  useOutsideNavClick(navRef, openNav, closeMenu);

  return (
    <StyledHeader ref={navRef}>
      <MenuButton onClick={openMenu} open={openNav}>
        <span className="menu-btn-burger" />
      </MenuButton>

      <CustomApplyButton to="/apply" show={`${!openNav}`}>
        Our Programs
      </CustomApplyButton>

      <LogoWrapper to="/" onClick={closeMenu}>
        <img src="/logo.png" alt="logo" width="60" className="" />
      </LogoWrapper>

      {/* <div to="/" onClick={closeMenu}>
        <img src="/logo.png" alt="logo" width="60" className='mb-[-30px]' />
      </div> */}

      <Nav open={openNav}>
        <ul className="menu-nav">
          <li
            className={`menu-nav-item ${path === '/about' && 'active'}`}
            onClick={closeMenu}
          >
            <Link to="/about" className="menu-nav-link">
              About Us
            </Link>
          </li>
          {/* <li className="">
           
          </li> */}

          <li
            className={`menu-nav-item ${path === '/scholars' && 'active'}`}
            onClick={closeMenu}
          >
            <Link to="/scholars" className="menu-nav-link">
              Our Scholars
            </Link>
          </li>

          <li
            className={`menu-nav-item ${path === '/program' && 'active'}`}
            onClick={closeMenu}
          >
            <Link to="/program" className="menu-nav-link">
              Scholar Program
            </Link>
          </li>
          <li
            className={`menu-nav-item ${path === '/program' && 'active'}`}
            onClick={closeMenu}
          >
            <Link to="/foundation" className="menu-nav-link">
              Foundation
            </Link>
          </li>

          <li className="menu-nav-item" onClick={closeMenu}>
            <a
              href="https://medium.com/@techgenafrica"
              target="_blank"
              rel="noopener noreferrer"
              className="menu-nav-link"
            >
              Blog
            </a>
          </li>

          <li
            className={`menu-nav-item ${path === '/contact' && 'active'}`}
            onClick={closeMenu}
          >
            <Link to="/contact" className="menu-nav-link">
              Contact Us
            </Link>
          </li>

          <li className="menu-nav-item" onClick={closeMenu}>
            <Link to="/apply" className="menu-nav-link apply-btn mb-[10px]">
              Our Programs
            </Link>
          </li>

          <ToggleTheme />
        </ul>
      </Nav>
    </StyledHeader>
  );
}

const mapStateToProps = createStructuredSelector({
  openNav: selectOpenNav,
});

export default connect(mapStateToProps)(Navbar);
