import React from 'react';
import '../../assets/css/main.css';
// import { usePaystackPayment } from 'react-paystack';

// import { ModalContent, StyledBtn } from './support-btn.styles';
// import Modal from '../modal/modal.comp';
// import FormField from '../form-field/form-field.comp';
// import appConfig from '../../config';

export default function SupportBtn() {
  // const [open, setOpen] = useState(false);
  // const [values, setValues] = useState({
  //   email: '',
  //   amount: '',
  // });

  // const { email, amount } = values;

  // const handleChange = (e) => {
  //   setValues({ ...values, [e.target.name]: e.target.value });
  // };

  // function callback(response) {
  //   // const { message, status, transaction } = response;
  //   // const paymentDetails = { message, status, transaction };
  // }

  // function onClose() {
  //   setOpen(true);
  // }

  // const config = {
  //   reference: new Date().getTime(),
  //   email,
  //   amount: parseFloat(amount * 100),
  //   publicKey: appConfig.api_key,
  // };

  // const initializePayment = usePaystackPayment(config);

  // const donateNow = () => {
  //   setOpen(false);
  //   initializePayment(callback, onClose);
  // };

  return (
    <>
      {/* <Modal open={open} close={() => setOpen(false)} className="support-modal">
        <ModalContent>
          <div className="left-bubble"></div>
          <div className="right-bubble"></div>
          <div className="mid-bubble"></div>
          <h1>Support us</h1>

          <FormField
            name="email"
            label="Input your mail"
            placeholder="example@gmail.com"
            type="email"
            value={email}
            onChange={handleChange}
          // error={errors.email}
          />

          <FormField
            name="amount"
            label="Amount (in ₦)"
            placeholder="Amount"
            type="number"
            value={amount}
            onChange={handleChange}
          // error={errors.email}
          />

          <StyledBtn onClick={donateNow}>Continue</StyledBtn>
        </ModalContent>
      </Modal>

      <StyledBtn onClick={() => setOpen(true)}>Sponsor a candidate</StyledBtn> */}
      <div className="parent-paystack">
        <a href="https://paystack.com/pay/impact-a-life " className="paystack">
          Sponsor a candidate
        </a>
      </div>
    </>
  );
}
