import React from 'react';
import { Link } from 'react-router-dom';
import { FooterContainer } from './footer.styled';
import Facebook from '../../assets/icons/facebook.png';
import Twitter from '../../assets/icons/twitter.png';
import Instagram from '../../assets/icons/instagram.png';
import Linkedin from '../../assets/icons/linkedin.png';
import SupportBtn from '../support-btn/support-btn.comp';
import { MdAddLocationAlt } from 'react-icons/md';

function Footer() {
  return (
    <FooterContainer className="container">
      <div className="first-row">
        <div className="column">
          <h4 className="heading">Explore</h4>
          <Link to="/apply" className="column-item">
            Program
          </Link>
          <Link to="/scholars" className="column-item">
            TGA Scholars
          </Link>
          <Link to="/community" className="column-item">
            Community
          </Link>
          <Link to="/publications" className="column-item">
            Publications
          </Link>
          <Link to="/apply" className="column-item bordered">
            Apply
          </Link>
        </div>

        <div className="column">
          <h4 className="heading">Information</h4>
          <Link to="/about" className="column-item">
            About
          </Link>
          <a
            href="https://medium.com/@techgenafrica"
            target="_blank"
            rel="noopener noreferrer"
            className="column-item"
          >
            Blog
          </a>
          <Link to="/contact" className="column-item">
            Contact us
          </Link>
          <Link to="/privacy" className="column-item">
            Privacy and Policy
          </Link>
          <Link to="/terms" className="column-item">
            Terms and Condition
          </Link>
        </div>

        <div className="column">
          <h4 className="heading">Follow Us</h4>
          <div className="column-item social-icon-container">
            <Link
              to={{ pathname: 'https://www.instagram.com/techgenafrica' }}
              className="social-icon"
              target="_blank"
            >
              <img alt="" src={Instagram} />
            </Link>
            <Link
              to={{ pathname: 'https://www.twitter.com/techgenafrica1' }}
              target="_blank"
              className="social-icon"
            >
              <img alt="" src={Twitter} />
            </Link>
            <Link
              to={{ pathname: 'https://www.facebook.com/techgenafrica' }}
              target="_blank"
              className="social-icon facebook"
            >
              <img alt="" src={Facebook} />
            </Link>
            <Link
              to={{ pathname: 'https://www.linkedin.com/company/techgenafrica' }}
              target="_blank"
              className="social-icon"
            >
              <img alt="" src={Linkedin} />
            </Link>
          </div>
          <h4 className="heading column-item">Contact Us</h4>
          <a href="tel:+2349027452699" className="column-item">
            +234 902 745 2699
          </a>
          <a
            href="mailto:hello@techgenafrica.com"
            target="_blank"
            rel="noopener noreferrer"
            className="column-item"
          >
            hello@techgenafrica.com
          </a>
          <Link to="/contact" className="column-item bordered">
            Contact us
          </Link>
        </div>

        <div className="column">
          <h4 className="heading">Sponsor a candidate</h4>
          <p className="column-item">
            Support students who need financial assistance by donating to our 501(c)(3)
            charity arm
          </p>

          <SupportBtn />
        </div>
      </div>
      <div className="parent-address">
        <div className="address">
          <MdAddLocationAlt className="head-q" />
          <h1>Office</h1>
          <p className="p-one">Come say hello at our office HQ</p>
          <p className="p-two">Plot 444,1R Crescent,FHA,Lugbe Abuja</p>
        </div>
      </div>
      <div className="second-row">
        <p>&copy; {new Date().getFullYear()} TechGen Africa. All rights reserved.</p>
      </div>
    </FooterContainer>
  );
}

export default Footer;
