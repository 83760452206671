import styled from 'styled-components';

export const StyledFallback = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo {
    width: 200px;
    height: 200px;
    background-image: url('/logo512.png');
    background-size: cover;
    background-repeat: no-repeat;
  }
`;
