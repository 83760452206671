import React from 'react';
import { Link } from 'react-router-dom';

import { StyledErrorPage } from './error-page.styled';

export default function ErrorPage({ btnClick, header, text, btnText }) {
  return (
    <StyledErrorPage>
      <Link to="/">
        <img src="/logo.png" alt="" />
      </Link>
      <div className="error-container">
        <div>{header}</div>
        <p>{text}</p>
        <button onClick={() => btnClick && btnClick()}>{btnText}</button>
      </div>
    </StyledErrorPage>
  );
}
