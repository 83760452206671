import styled from 'styled-components';

export const StyledToggle = styled.div`
  position: relative;
  display: inline-block;

  .sun,
  .moon {
    display: block;
    font-size: 2rem;
    width: fit-content;
    height: fit-content;
  }

  .moon {
    position: absolute;
    inset: 0;
  }

  .moon-logo {
    opacity: 0;
    transform: translateY(20%) rotateZ(50deg);
    transition: all 1s ease-out;
  }

  .sun-logo {
    opacity: 1;
    transform: translateY(0) rotateZ(0deg);
    transition: all 1s ease-out;
  }

  .animate-sun {
    opacity: 0;
    transform: translateY(20%) rotateZ(100deg);
  }

  .animate-moon {
    opacity: 1;
    transform: translateY(0%) rotateZ(0deg);
  }
`;
