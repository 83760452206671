import React from 'react';
import { withRouter } from 'react-router-dom';

import ErrorPage from '../error-page/error-page.comp';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasErrored: false,
    };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    this.setState({ hasErrored: true });
    console.log(error, info);
  }

  render() {
    if (this.state.hasErrored)
      return (
        <ErrorPage
          header="Sever Error 😣"
          text="opps, something went wrong."
          btnClick={() => {
            // this.props.history.push('/');
            window.location.reload();
          }}
          btnText="Reload Page"
        />
      );

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
