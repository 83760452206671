import React from 'react';

import ErrorPage from '../../components/error-page/error-page.comp';

export default function Error404({ history }) {
  return (
    <ErrorPage
      header="Got Lost?🧐"
      text="Sorry page not found"
      btnClick={() => history.push('/')}
      btnText="Go Back Home"
    />
  );
}
