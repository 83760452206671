export const lightTheme = {
  theme: 'light',
  colors: {
    primary: '#51B2F4',
    secondary: '#00CC88',
    bg: '#fff',
    color: '#343333',
    headerColor: '#000000',
    itemBg: '#FCFCFC',
    borderColor: 'rgba(0, 0, 0, 0.1)',
    contactBg: '#FCFCFC',
    levelBg: '#FFFFFF',
  },

  sizes: {
    h1: {
      sm: '1.8rem',
      md: '2.5rem',
      lg: '3rem',
      xl: '3.5rem',
    },

    h2: {
      sm: '1.2rem',
      md: '1.5rem',
      lg: '2rem',
      xl: '2.5rem',
    },
  },
};

export const darkTheme = {
  theme: 'dark',
  colors: {
    primary: '#51B2F4',
    secondary: '#00CC88',
    bg: '#000',
    color: '#FCFCFC',
    headerColor: '#FFFFFF',
    itemBg: '#171717',
    borderColor: 'rgba(0, 0, 0, 0.8)',
    contactBg: '#171717',
    levelBg: '#343333',
  },

  sizes: {
    h1: {
      sm: '1.8rem',
      md: '2.5rem',
      lg: '3rem',
      xl: '3.5rem',
    },

    h2: {
      sm: '1.2rem',
      md: '1.5rem',
      lg: '2rem',
      xl: '2.5rem',
    },
  },
};
