import styled from 'styled-components';

export const StyledErrorPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  img {
    position: absolute;
    top: 2rem;
    left: 2rem;
    width: 60px;
    height: 60px;
    object-fit: cover;
  }

  .error-container {
    text-align: center;

    & > div {
      font-size: 3rem;
      font-weight: 900;
      color: ${({ theme: { theme } }) => (theme === 'dark' ? '#969696' : '#FCFCFC')};
      text-shadow: 1px 0 0 #51b2f447, -1px 0 0 #51b2f447, 0 1px 0 #51b2f447,
        0 -1px 0 #51b2f447, 1px 1px #51b2f447, -1px -1px 0 #51b2f447, 1px -1px 0 #51b2f447,
        -1px 1px 0 #51b2f447;

      @media (min-width: 768px) {
        font-size: 5rem;
      }
    }

    p {
      color: ${({ theme: { theme } }) => (theme === 'dark' ? '#969696' : '#575757')};
    }

    button {
      background: #ff822e;
      border-radius: 4px;
      border: none;
      padding: 1rem 2rem;
      color: #fff;
      margin-top: 1.5rem;
      cursor: pointer;

      &:focus {
        outline: none;
        border: none;
      }
    }
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1600px) {
  }
`;
